import React from 'react'
import { Container } from 'react-bootstrap'

import Menu from '~components/Menu'

import { footer, footer__logo, footer__links, footer__copy } from './style.module.scss'

const Footer = ({ siteTitle, hasSpot = true }) => {
  return (
    <Container as="footer" className={footer}>
      <div className={footer__logo}>{siteTitle}</div>
      <Menu variant="footer" />
      <ul className={footer__links}>
        <li>
          <a href="#">Privacy Policy</a>
        </li>
        <li>
          <a href="#">Terms of Use</a>
        </li>
      </ul>
      <div className={footer__copy}>© 2021 VeriBeat. All Rights Reserved</div>
    </Container>
  )
}

export default Footer
