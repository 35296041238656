import React, { useState } from 'react'
import { useLocation } from '@reach/router'
import { Link, withPrefix } from 'gatsby'
import { Nav } from 'react-bootstrap'
import Popup from '~components/Popup'
import cn from 'classnames'
import gsap from 'gsap'
import ScrollToPlugin from 'gsap/ScrollToPlugin'

import MENU from './constants'

const Menu = ({ variant }) => {
  const location = useLocation()
  const isHomepage = location.pathname === withPrefix('/')

  gsap.registerPlugin(ScrollToPlugin)

  const handleScroll = (e, link) => {
    e.preventDefault()
    gsap.to(window, { scrollTo: link, ease: 'power2' })
  }

  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  return (
    <>
      <Nav className={cn({ [`nav--${variant}`]: variant })} as="ul">
        {MENU.map(({ name, link }) => (
          <Nav.Item as="li" key={name}>
            {!link ? (
              <Nav.Link as="button" onClick={handleShow}>
                {name}
              </Nav.Link>
            ) : isHomepage ? (
              <Nav.Link href={`${link}`} onClick={(e) => handleScroll(e, link)}>
                {name}
              </Nav.Link>
            ) : (
              <Link to={`/${link}`} className="nav-link" activeClassName="active">
                {name}
              </Link>
            )}
          </Nav.Item>
        ))}
      </Nav>
      <Popup show={show} handleClose={handleClose} />
    </>
  )
}

export default Menu
