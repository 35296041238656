import React, { useState } from 'react'
import { Container, Button } from 'react-bootstrap'
import Popup from '~components/Popup'

import { LightAsync as SyntaxHighlighter } from 'react-syntax-highlighter'
import python from 'react-syntax-highlighter/dist/esm/languages/hljs/python'
import java from 'react-syntax-highlighter/dist/esm/languages/hljs/java'

import { atomOneDarkReasonable } from 'react-syntax-highlighter/dist/esm/styles/hljs'
import cn from 'classnames'

import * as s from './style.module.scss'

import { DATA, TABS } from './constants'

import sprite from '~img/icons.svg'

SyntaxHighlighter.registerLanguage('python', python)
SyntaxHighlighter.registerLanguage('java', java)

const Features = () => {
  const [activeTab, setActiveTab] = useState('python')

  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  return (
    <div className={s.features} id="features">
      <Container>
        <h2 className={s.features__title}>Features</h2>
        <h3 className={s.features__subtitle}>Instant track metadata</h3>
        <ul className={s.features__items}>
          {DATA.map(({ icon, title, descr }) => (
            <li className={s.feature} key={icon}>
              <div className={s.feature__icon}>
                <svg xmlns="http://www.w3.org/2000/svg" width={60} height={60} viewBox={`0 0 ${60} ${60}`}>
                  <use fill="currentColor" xlinkHref={`${sprite}#${icon}`} />
                </svg>
              </div>
              <h4 className={s.feature__title}>{title}</h4>
              <p className={s.feature__descr}>{descr}</p>
            </li>
          ))}
        </ul>
      </Container>
      <Container className={s.features__bottom}>
        <div>
          <h3 className={s.features__subtitle}>API Access</h3>
          <p>Analyze songs while identifying curse words and explicit tracks programmatically.</p>
          <div className={s.features__btns}>
            <Button onClick={handleShow} variant="white">
              Request a Demo
            </Button>
          </div>
          <ul className={s.features__links}>
            <li>
              <a className={s.features__link} href="#">
                Documentation
                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox={`0 0 ${24} ${24}`}>
                  <use fill="currentColor" xlinkHref={`${sprite}#chevron`} />
                </svg>
              </a>
            </li>
            <li>
              <a className={s.features__link} href="#">
                About our API
                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox={`0 0 ${24} ${24}`}>
                  <use fill="currentColor" xlinkHref={`${sprite}#chevron`} />
                </svg>
              </a>
            </li>
          </ul>
        </div>
        <div className={s.tabs}>
          <ul className={s.tabs__switcher}>
            {TABS.map(({ lang }) => (
              <li key={lang}>
                <button className={cn(s.tab, { [s.active]: lang === activeTab })} onClick={(e) => setActiveTab(lang)}>
                  {lang}
                </button>
              </li>
            ))}
          </ul>
          <div className={s.tabs__container}>
            {TABS.map(({ lang, code }) => (
              <div className={cn(s.tab__code, { [s.visible]: lang === activeTab })} key={`t_${lang}`}>
                <SyntaxHighlighter
                  language={lang}
                  style={atomOneDarkReasonable}
                  showLineNumbers={true}
                  customStyle={{
                    padding: 0,
                    margin: 0,
                    background: 'none',
                  }}
                  lineNumberContainerStyle={{
                    float: 'left',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 0,
                    textAlign: 'right',
                  }}
                  lineNumberStyle={{
                    minWidth: 19,
                    padding: 0,
                    marginRight: 32,
                  }}
                >
                  {code}
                </SyntaxHighlighter>
              </div>
            ))}
          </div>
        </div>
      </Container>
      <Popup show={show} handleClose={handleClose} />
    </div>
  )
}

export default Features
