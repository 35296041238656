// extracted by mini-css-extract-plugin
export const features = "l_W";
export const features__title = "l_X";
export const features__subtitle = "l_Y";
export const features__items = "l_Z";
export const features__btns = "l_0";
export const features__links = "l_1";
export const features__link = "l_2";
export const features__bottom = "l_3";
export const feature = "l_4";
export const feature__icon = "l_5";
export const feature__title = "l_6";
export const feature__descr = "l_7";
export const tabs__switcher = "l_8";
export const tab = "l_9";
export const active = "l_bb";
export const tab__code = "l_bc";
export const visible = "l_bd";