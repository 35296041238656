import React, { useState } from 'react'
import { Container, Button } from 'react-bootstrap'
import Popup from '~components/Popup'
import cn from 'classnames'
import { cta, cta__title, cta__btns } from './style.module.scss'
const Cta = () => {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  return (
    <Container className={cta} id="contact">
      <h2 className={cn('h3', cta__title)}>Try us out to save time, money, and reduce fraud at your company</h2>
      <div className={cta__btns}>
        <Button onClick={handleShow}>Request a Demo</Button>
      </div>

      <Popup show={show} handleClose={handleClose} />
    </Container>
  )
}

export default Cta
