import React from 'react'
import { Container } from 'react-bootstrap'
import { StaticImage } from 'gatsby-plugin-image'
import { about, about__ill, about__title, about__descr } from './style.module.scss'

const About = () => {
  return (
    <Container as="section" className={about} id="about">
      <div className={about__ill}>
        <StaticImage
          src="./img/laptop.svg"
          alt="Laptop"
          layout="constrained"
          placeholder="blurred"
          quality="100"
          width={671}
          height={587}
        />
      </div>
      <div>
        <h2>About us</h2>
        <h3 className={about__title}>Streamline music distribution and stop fraudulent releases before they happen</h3>
        <div className={about__descr}>
          <p>
            Streamline track analysis, protect your company against fraud, reduce your workload, distribute music faster
            and more accurately with VeriBeat.
          </p>
          <p>
            Using VeriBeat, automatically detect curse words, classify explicit tracks, identify primary and multiple
            secondary genre’s, generate ID3 metadata tags, meta-data, upscaling / up sampling detection and more.
          </p>
        </div>
      </div>
    </Container>
  )
}

export default About
