import React from 'react'
import { Container } from 'react-bootstrap'

import {
  tryus,
  tryus__title,
  tryus__subtitle,
  tryus__items,
  tryus__item,
  tryus__icon,
  tryus__item__title,
  tryus__item__descr,
} from './style.module.scss'

import sprite from '~img/icons.svg'

import DATA from './constants'

const Try = () => {
  return (
    <div className={tryus}>
      <Container>
        <h2 className={tryus__title}>try us out</h2>
        <h3 className={tryus__subtitle}>Easy integration</h3>
        <ul className={tryus__items}>
          {DATA.map(({ icon, title, descr }) => (
            <li className={tryus__item} key={icon}>
              <div className={tryus__icon}>
                <svg xmlns="http://www.w3.org/2000/svg" width={75} height={75} viewBox={`0 0 ${75} ${75}`}>
                  <use fill="currentColor" xlinkHref={`${sprite}#${icon}`} />
                </svg>
              </div>
              <h4 className={tryus__item__title}>{title}</h4>
              <p className={tryus__item__descr}>{descr}</p>
            </li>
          ))}
        </ul>
      </Container>
    </div>
  )
}

export default Try
