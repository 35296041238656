import React from 'react'
import { Container } from 'react-bootstrap'
import { how, how__subtitle, how__items, how__item, how__item__title, how__item__descr } from './style.module.scss'

import DATA from './constants'

const How = () => {
  return (
    <Container className={how} id="how">
      <h2>how to use our service?</h2>
      <h3 className={how__subtitle}>Track metadata made easy</h3>
      <ul className={how__items}>
        {DATA.map(({ title, descr }) => (
          <li className={how__item} key={title}>
            <h4 className={how__item__title}>{title}</h4>
            <p className={how__item__descr}>{descr}</p>
          </li>
        ))}
      </ul>
    </Container>
  )
}

export default How
