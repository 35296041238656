const DATA = [
  {
    title: 'Plug and play',
    descr:
      'Integration is easy! Just install our docker container, run our desktop app, or ping our API to start using VeriBeat.',
  },
  {
    title: 'Analyze your tracks and catalog',
    descr: 'Install our docker container on your servers, run our desktop app, or ping our API.',
  },
  {
    title: 'Smart AI and machine learning',
    descr:
      'VeriBeat machine learning does the work instead of your team. Trained using over 2,000,000 unique tracks for accuracy of 97% or greater',
  },
  {
    title: 'Instant metadata',
    descr:
      "Detect explicit tracks, identify curse words, classify genre's, detect fraudulent songs, and release songs faster!  We automatically add your metadata to MusicBrainz, Discogs and more!",
  },
]

export default DATA
