const MENU = [
  {
    name: 'About us',
    link: '#about',
  },
  {
    name: 'Features',
    link: '#features',
  },
  {
    name: 'How to use',
    link: '#how',
  },
  {
    name: 'Contact us',
    modal: true,
  },
]

export default MENU
