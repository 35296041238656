// extracted by mini-css-extract-plugin
export const hero = "k_K";
export const hero__lead = "k_L";
export const hero__buttons = "k_M";
export const hero__ill = "k_N";
export const hero__img = "k_P";
export const hero__icon = "k_Q";
export const drum = "k_R";
export const maracas = "k_S";
export const xylophone = "k_T";
export const guitar = "k_V";