import * as React from 'react'
import { Link } from 'gatsby'
import { Container, Dropdown } from 'react-bootstrap'
import { header, header__inner, header__logo, toggler } from './style.module.scss'

import Menu from '~components/Menu'
// import sprite from '~img/icons.svg'

const Header = ({ siteTitle }) => (
  <Container as="header" className={header}>
    <div className={header__logo}>VeriBeat</div>
    <Menu variant="header" />
    <Dropdown className="dropdown--header">
      <Dropdown.Toggle as="button" className={toggler}>
        <span className="dropdown-hamb">
          <span className="dropdown-hamb-icon"></span>
        </span>
      </Dropdown.Toggle>
      <Dropdown.Menu
        className="dropdown-menu--header"
      >
        <Menu variant="dropdown" />
      </Dropdown.Menu>
    </Dropdown>
  </Container>
)

export default Header
