import React, { useState } from 'react'
import { Button, Modal, Form } from 'react-bootstrap'
import cn from 'classnames'
import * as s from './style.module.scss'

import sprite from '~img/icons.svg'

import { useForm, ValidationError } from '@formspree/react'

const Popup = ({ show, handleClose }) => {
  const [state, handleSubmit] = useForm('moqyveay')
  const [validated, setValidated] = useState(false)

  const onSubmit = (event) => {
    event.preventDefault()
    const form = event.currentTarget
    if (form.checkValidity() === false) {
    } else {
      handleSubmit(event).then((res) => {
        setValidated(false)
        handleClose()
      })
    }

    setValidated(true)
  }

  return (
    <Modal className={s.contact} show={show} onHide={handleClose}>
      <div className={s.contact__wrap}>
        <Form noValidate validated={validated} onSubmit={onSubmit} className={s.contact__form}>
          <h2 className={s.contact__title}>Contact Us</h2>
          <div className={cn(s.contact__row, s.names)}>
            <Form.Control type="text" placeholder="First Name" name="first_name" required />
            <Form.Control type="text" placeholder="Last Name" name="last_name" required />
          </div>
          <Form.Control
            type="email"
            placeholder="Enter email"
            name="email"
            pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
            required
          />
          <Form.Control type="tel" pattern="\+?[\d]+" placeholder="Contact Phone" name="contact_phone" required />
          <Form.Control type="text" placeholder="Company Name" name="company_name" />
          <div className={cn(s.contact__row, s.subject)}>
            <Form.Label>Subject:</Form.Label>
            <Form.Control as="select" defaultValue="Request a Demo" name="subject" required>
              <option>General Question</option>
              <option>Request a Demo</option>
              <option>Partnerships</option>
              <option>Support</option>
              <option>Other</option>
            </Form.Control>
          </div>
          <Form.Control as="textarea" rows={4} placeholder="Comment" name="commet" required />
          <Button type="submit" block>
            Request a Demo
          </Button>
        </Form>
      </div>
      <button onClick={handleClose} className={s.contact__close}>
        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox={`0 0 ${24} ${24}`}>
          <use fill="currentColor" xlinkHref={`${sprite}#cross`} />
        </svg>
      </button>
    </Modal>
  )
}

export default Popup
