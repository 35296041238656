import * as React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import SEO from '~components/seo'
import Layout from '~components/Layout'
import Hero from '~components/Hero'
import About from '~components/About'
import Features from '~components/Features'
import How from '~components/How'
import Try from '~components/Try'
import Cta from '~components/Cta'

const IndexPage = () => (
  <Layout>
    <SEO />
    <Hero />
    <About />
    <Features />
    <How />
    <Try />
    <Cta />
  </Layout>
)

export default IndexPage
