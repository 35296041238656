import React, { useState } from 'react'
import { Container, Button } from 'react-bootstrap'
import Popup from '~components/Popup'
import ICONS from './constants'
import cn from 'classnames'
import * as s from './style.module.scss'
import sprite from '~img/icons.svg'
import heroImg from './img/hero.svg'

const Hero = () => {
  const [show, setShow] = useState(false)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  return (
    <Container as="section" className={s.hero}>
      <div>
        <h1>Instantly identify explicit tracks, genres and more!</h1>
        <p className={s.hero__lead}>
          We make life easy for distributors, publishers, and record labels. Stop fraud before it happens, and
          automatically identify explicit tracks, genre's, and generate track metadata.
        </p>
        <div className={s.hero__buttons}>
          <Button onClick={handleShow} as="a">
            Request a Demo
          </Button>
        </div>
      </div>
      <div className={s.hero__ill}>
        <div className={s.hero__img}>
          <img src={heroImg} alt="player" />
        </div>
        {ICONS.map((name) => (
          <div className={cn(s.hero__icon, s[`${name}`])} key={name}>
            <svg xmlns="http://www.w3.org/2000/svg" width={50} height={50} viewBox={`0 0 ${50} ${50}`}>
              <use fill="currentColor" xlinkHref={`${sprite}#${name}`} />
            </svg>
          </div>
        ))}
      </div>
      <Popup show={show} handleClose={handleClose} />
    </Container>
  )
}

export default Hero
