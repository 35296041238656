const DATA = [
  {
    icon: 'e',
    title: 'Explicit Tracks',
    descr: 'Still listening to each individual track? VeriBeat identifies explicit tracks 97% or greater accuracy.',
  },
  {
    icon: 'id',
    title: 'Metadata and ID3 tags',
    descr: 'Ensure meta-data accuracy and completion in any language and across all DSP streaming services',
  },
  {
    icon: 'asterisks',
    title: 'Curse Word Identification',
    descr:
      'If one or more curse words are detected,  receive a list of curse words and the exact playback location of each occurrence',
  },
  {
    icon: 'vinyl',
    title: 'Song & Album Theft',
    descr:
      'Easily detect fraud before it happens. VeriBeat uses compositional data analysis to identify exactly which songs are fraudulent ',
  },
  {
    icon: 'guitar_genre',
    title: 'Genre Classification',
    descr: "Save your team's time and let VeriBeat automatically identify and rank up to 5 genre's per track",
  },
  {
    icon: 'globe',
    title: 'Spoken Languages',
    descr:
      'Identify primary and secondary languages from any track, and instantly add appropriate language location metadata',
  },
]

const TABS = [
  {
    lang: 'python',
    code: `import requests

url = 'https://api.veribeat.com/v1/analyze/track'
data = {"track-name": "the best song in the world", "artist": "unknown"}
r = requests.post(url, auth={'API_KEY',''}, json=data, songFile)`,
  },
  {
    lang: 'java',
    code: `import java.util.Scanner;

public class HelloWorld {
  public static void main(String[] args) {
    Scanner reader = new Scanner(System.in);
    System.out.print("Enter a number: ");
    int number = reader.nextInt();

    System.out.println("You entered: " + number);
  }
}`,
  },
]

export { DATA, TABS }
