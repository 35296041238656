const DATA = [
  {
    icon: 'api',
    title: 'API Integration',
    descr: 'Add instant song metadata to your organization by simply calling our API to from your software',
  },
  {
    icon: 'docker',
    title: 'Docker',
    descr: 'Deploy our docker container directly to your existing infrastructure for seamless integration',
  },
  {
    icon: 'desktop',
    title: 'Desktop Application',
    descr:
      'Want something more simple? Use our desktop app and view and share your results right from our desktop app!',
  },
]

export default DATA
